import { Store } from 'vuex'
import { NuxtCookies } from 'cookie-universal-nuxt'
import {
  ApiResourceBase,
  JsonApiService,
  ResourceCollection,
} from '@anny.co/vue-jsonapi-orm'
import { EmbedService } from '@/bookingbuddy-shop/plugins/embedServiceInit'
import { CustomerAccount } from '@/shared/jsonapi-orm/bookingbuddy/CustomerAccount'
import { Order, OrderStatus } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { Favorite } from '@/shared/jsonapi-orm/bookingbuddy/Favorite'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import dayjs from 'dayjs'
import { JsonApiIdentifier } from '@anny.co/vue-jsonapi-orm/dist/JsonApiTypes'
import { Context, Plugin } from '@nuxt/types'
import Vue from 'vue'
import { QueryBuilder } from '@anny.co/vue-jsonapi-orm/dist/builder/QueryBuilder'
import {
  AddOnsByService,
  BookingAddOnParams,
  RecurringSettings,
  SubBookingParams,
  SubBookingsByService,
} from '@/shared/jsonapi-orm/bookingbuddy/Booking'

/*
 * Declare modules for type hinting.
 */
declare module 'vue/types/vue' {
  interface Vue {
    $shopService: ShopService
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $shopService: ShopService
  }
  interface Context {
    $shopService: ShopService
  }
}

/*
 * Define constants
 */

// multi-order keys for succeeded orders
export const ORDER_IDS_KEY = 'oids'
export const ORDER_ACCESS_TOKENS_KEY = 'oats'

export const ORDER_ID_KEY = 'oid'
export const ORDER_ACCESS_TOKEN_KEY = 'oat'
export const REMEMBER_CUSTOMER_TOKEN_KEY = 'rct'

type OrderCookieKeys = {
  orderId: string
  orderAccessToken: string
  rememberCustomerToken: string
}
export const orderCookieKeys = (prefix: string): OrderCookieKeys => {
  return {
    orderId: prefix + ORDER_ID_KEY,
    orderAccessToken: prefix + ORDER_ACCESS_TOKEN_KEY,
    rememberCustomerToken: prefix + REMEMBER_CUSTOMER_TOKEN_KEY,
  }
}

export interface AddBookingPayload {
  resource_id: string | number | null
  service_id: string | { [serviceId: string]: number } | null
  start_date: string | null
  end_date: string | null
  description: string | null
  note?: string | null
  customer_note: string | null
  booking_add_ons: BookingAddOnParams[]
  sub_bookings: SubBookingParams[]
  quantity?: number
  recurring?: RecurringSettings | null
  add_ons_by_service: AddOnsByService
  sub_bookings_by_service: SubBookingsByService
}

const defaultOrderInclude = [
  'customer',
  'voucher',
  'bookings.booking_add_ons.add_on',
  'bookings.sub_bookings.resource',
  'bookings.sub_bookings.service',
  'bookings.series',
  'bookings.customer',
  'bookings.service.custom_forms.custom_fields',
  'bookings.cancellation_policy',
  'bookings.resource.cover_image',
  'bookings.resource.parent',
  'bookings.resource.category',
  'bookings.reminders',
  'bookings.booking_series',
  'sub_orders.bookings',
  'sub_orders.organization.legal_documents',
]

/*
 * Define shop service class.
 * The shop service class is handling the active order,
 * likes, favorites and liked or recent visited resources.
 * You can access the service through context.$shopService
 *
 * This service requires the authService!
 */
export class ShopService {
  store: Store<any>
  cookies: NuxtCookies
  apiService: JsonApiService
  embedService: EmbedService | null
  secureCookies = true
  previewToken: string | null = null
  initialOrderId: string | null = null
  initialOrderAccessToken: string | null = null
  layout: 'default' | 'account' | 'embedded' = 'default'

  _orderCookieKeys: OrderCookieKeys

  activeOrder: Order | null = null
  likedResourceIds: string[] = []
  likedMemberIds: string[] = []
  favorites: Favorite[] = []
  likes: Favorite[] = []
  recent: Favorite[] = []
  viewed: Favorite[] = []
  likedResourceCollection: ResourceCollection<Resource> | null = null
  likedMemberCollection: ResourceCollection<CustomerAccount> | null = null
  recentResourceCollection: ResourceCollection<Resource> | null = null

  constructor(
    protected ctx: Context,
    query: Record<string, any>,
    store: Store<any>,
    apiService: JsonApiService,
    embedService: EmbedService | null,
    cookies: NuxtCookies,
    secureCookies = true,
    previewToken?: string | null
  ) {
    this.store = store
    this.apiService = apiService
    this.embedService = embedService
    this.previewToken = previewToken ?? null
    if (embedService) {
      this.layout = 'embedded'
    }
    this.cookies = cookies
    this.secureCookies = secureCookies

    this._orderCookieKeys = orderCookieKeys(ctx.$config.storagePrefix || '')

    this.initActiveOrder(query)
    this.initRememberCustomer(query)

    // watch customer account change
    this.ctx.$authService.watchCustomerAccountChanged(
      async (oldAccount, newAccount) => {
        this.handleChangedAccount()
      }
    )
    this.handleChangedAccount()

    // subscribe store to get updates
    store.subscribe((mutation) => {
      if (mutation.type === 'cart/setOrder') {
        this.handleActiveOrderUpdate()
      } else if (mutation.type === 'cart/setRememberCustomerToken') {
        this.handleRememberCustomerTokenUpdate()
      }
    })
  }

  get isLoggedIn(): boolean {
    return this.ctx.$authService.account !== null
  }

  /**
   * @deprecated please use $authService.account instead
   */
  get account(): CustomerAccount | null {
    return this.ctx.$authService.account
  }

  /**
   * Initialize active order id.
   * @param query
   */
  private initActiveOrder(query: Record<string, any>) {
    // read from query param
    let orderId = query.orderId ?? query[ORDER_ID_KEY]
    let orderAccessToken = query.accessToken ?? query[ORDER_ACCESS_TOKEN_KEY]
    // fall back to cookies
    if (!orderId && !this.embedService) {
      orderId = this.cookies.get(this._orderCookieKeys.orderId)
      orderAccessToken = this.cookies.get(
        this._orderCookieKeys.orderAccessToken
      )
    }
    // init order id in store
    if (orderId && orderAccessToken) {
      this.initialOrderId = orderId
      this.initialOrderAccessToken = orderAccessToken
    }
  }

  /**
   * Read customer token from query.
   * @param query
   * @private
   */
  private initRememberCustomer(query: Record<string, any>) {
    let rct = query.r_token ?? query[REMEMBER_CUSTOMER_TOKEN_KEY]
    if (!rct && !this.embedService) {
      rct = this.cookies.get(this._orderCookieKeys.rememberCustomerToken)
    }
    if (rct) {
      this.store.commit('cart/setRememberCustomerToken', rct)
    }
  }

  /**
   * Handle new order id on change.
   * @private
   */
  private handleActiveOrderUpdate() {
    // hydrate order model
    const oldOrder = this.activeOrder
    this.activeOrder = this.store.getters['cart/order'](this.apiService)

    // handle changed order
    if (oldOrder?.id !== this.activeOrder?.id) {
      // set cookies if not embedded - embedService will handle the rest
      if (!this.embedService) {
        if (this.activeOrder) {
          // expiry is not updated, so we need to set a higher value than usual
          const expires = dayjs().add(2, 'hours')
          this.cookies.set(this._orderCookieKeys.orderId, this.activeOrder.id, {
            path: '/',
            secure: this.secureCookies,
            expires: expires.toDate(),
          })
          this.cookies.set(
            this._orderCookieKeys.orderAccessToken,
            this.activeOrder.accessToken,
            {
              path: '/',
              secure: this.secureCookies,
              expires: expires.toDate(),
            }
          )
        } else {
          this.cookies.remove(this._orderCookieKeys.orderId)
          this.cookies.remove(this._orderCookieKeys.orderAccessToken)
        }
      } else {
        // embedded
        this.embedService.persistData(
          ORDER_ID_KEY,
          this.activeOrder?.id ?? null
        )
        this.embedService.persistData(
          ORDER_ACCESS_TOKEN_KEY,
          this.activeOrder?.accessToken ?? null
        )
      }
    }

    oldOrder?.$destruct()
  }

  /**
   * Store remember customer token in cookies.
   * @private
   */
  private handleRememberCustomerTokenUpdate() {
    const rememberCustomer = this.store.getters['cart/getRememberCustomerToken']
    if (this.embedService) {
      this.embedService.persistData(
        REMEMBER_CUSTOMER_TOKEN_KEY,
        rememberCustomer
      )
    } else {
      this.cookies.set(
        this._orderCookieKeys.rememberCustomerToken,
        rememberCustomer,
        {
          path: '/',
          secure: this.secureCookies,
          expires: dayjs().add(30, 'days').toDate(),
        }
      )
    }
  }

  handleChangedAccount() {
    if (this.ctx.$authService.account && !this.embedService) {
      this.layout = 'account'
    }

    // destruct collections on change
    this.likedResourceCollection?.$destruct()
    this.likedMemberCollection?.$destruct()
    this.recentResourceCollection?.$destruct()

    // initialize collection for liked and recent resources
    if (this.ctx.$authService.account) {
      this.likedResourceCollection = new ResourceCollection(
        this.ctx.$authService.account
          .relationApi<Resource>('likedResources')
          .with([
            'category',
            'cover_image',
            'group',
            'resource_properties.property',
            'services.group',
            'services.cancellationPolicy',
            'communities',
            'organization',
          ])
          .filter({
            exclude_child_resources: false,
          })
      )
      this.recentResourceCollection = new ResourceCollection(
        this.ctx.$authService.account
          .relationApi<Resource>('recentResources')
          .with(['coverImage', 'category'])
      )

      // initialize collection for liked and recent members
      this.likedMemberCollection = new ResourceCollection(
        this.ctx.$authService.account
          .relationApi<CustomerAccount>('likedMembers')
          .with(['profile_image', 'accountSkills.skill'])
      )
    } else {
      this.likedResourceCollection = null
      this.recentResourceCollection = null
    }

    if (this.ctx.$authService.account?.likedFavorites) {
      this.initFavoritesCache(this.ctx.$authService.account.likedFavorites)
    }
  }

  /**
   * Initialize favorites cache.
   * @param favorites
   */
  initFavoritesCache(favorites: Favorite[]) {
    this.favorites = favorites
    this.likes = favorites.filter((f) => f.liked)
    this.recent = favorites.filter((f) => f.recent)
    this.viewed = favorites.filter((f) => f.viewed)
    this.likedResourceIds = this.likes
      .filter((f) => f.model_type === Resource.jsonApiType)
      .map((f) => String(f.model_id))
    this.likedMemberIds = this.likes
      .filter((f) => f.model_type === CustomerAccount.jsonApiType)
      .map((f) => String(f.model_id))
  }

  /**
   * Update cache after adding or removing a favorite.
   * @param favorite
   */
  updateFavoriteCache(favorite: Favorite) {
    const index = this.favorites.indexOf(favorite)
    const newFavorites = [...this.favorites]
    if (index > -1) {
      newFavorites.splice(index, 1)
    } else {
      newFavorites.push(favorite)
    }
    this.initFavoritesCache(newFavorites)
  }

  /**
   * Toggle like for any model.
   * @param model
   */
  async likeModel(model: ApiResourceBase): Promise<Favorite | null | boolean> {
    // check if liked, then dislike
    let fav = this.getFavorite(model)
    if (fav) {
      try {
        this.updateFavoriteCache(fav)
        await fav.destroy()
        return null
      } catch (e) {
        console.log(e)
        this.updateFavoriteCache(fav)
        return false
      }
    } else {
      fav = new Favorite(this.apiService)
      fav.liked = true
      fav.recent = false
      fav.viewed = false
      fav.model_id = model.id
      fav.model_type = model.type
      fav.model = model
      try {
        this.updateFavoriteCache(fav)
        await fav.save(false)
        return fav
      } catch (e) {
        console.log(e)
        this.updateFavoriteCache(fav)
        return false
      }
    }
  }

  /**
   * Get favorite instance from cache.
   * @param model
   */
  getFavorite(model: JsonApiIdentifier): Favorite | undefined {
    return this.favorites.find((f) => {
      return String(f.model_id) === model.id && f.model_type === model.type
    })
  }

  /**
   * Check if resource was liked.
   * @param resource
   */
  hasLikedResource(resource: Resource | string) {
    const resourceId = typeof resource === 'string' ? resource : resource.id
    return this.likedResourceIds.includes(resourceId)
  }

  /**
   * Check if member was liked.
   * @param customerAccount
   */
  hasLikedMember(customerAccount: CustomerAccount | string) {
    const customerAccountId =
      typeof customerAccount === 'string' ? customerAccount : customerAccount.id
    return this.likedMemberIds.includes(customerAccountId)
  }

  getActiveOrderQueryParams(order?: Order | null, useInitialId = false) {
    if (!order) {
      order = this.activeOrder
    }
    if (!order && useInitialId) {
      return {
        [ORDER_ID_KEY]: this.initialOrderId,
        [ORDER_ACCESS_TOKEN_KEY]: this.initialOrderAccessToken,
        stateless: 1,
        preview_token: this.previewToken,
      }
    }
    return {
      [ORDER_ID_KEY]: order?.id,
      [ORDER_ACCESS_TOKEN_KEY]: order?.accessToken,
      stateless: 1,
      preview_token: this.previewToken,
    }
  }

  async fetchOrder(setActiveOrder = true): Promise<Order | undefined | null> {
    let order: Order
    this.ctx.store.commit('cart/updateField', {
      path: 'fetchingCart',
      value: true,
    })
    const apiService = this.ctx.$jsonApiService

    // Request order from custom path
    try {
      const query = await Order.api(apiService)
        .with(defaultOrderInclude)
        .query(this.getActiveOrderQueryParams(this.activeOrder, true))
        .where('status', OrderStatus.DRAFT)

      query.path = '/order'
      const response = await query.request()

      // Check if there was no data
      if (response.status === 204) {
        this.ctx.store.commit('cart/setOrder', null)
        return
      }

      // get order from response
      const resource = Order.resourceFromResponse(response.data, apiService)
      order = resource.data
    } catch (e) {
      console.log(e)
      return
    } finally {
      this.ctx.store.commit('cart/updateField', {
        path: 'fetchingCart',
        value: false,
      })
    }

    // Set order
    if (order && setActiveOrder) {
      this.ctx.store.commit('cart/setOrder', order)
    }
    return order
  }

  async addBooking(
    data: AddBookingPayload,
    order?: Order | null,
    setActiveOrder = true
  ): Promise<Order> {
    if (!order) {
      order = this.activeOrder
    }
    const apiService = this.ctx.$jsonApiService
    const response = await new QueryBuilder(
      `${Order.apiPath}/order/bookings`,
      apiService.getClient(Order),
      apiService
    )
      .include(defaultOrderInclude)
      .query({
        ...this.getActiveOrderQueryParams(order),
        stateless: true,
      })
      .request('', 'post', {
        data,
      })

    order = Order.resourceFromResponse(response.data, apiService).data
    if (setActiveOrder) {
      this.ctx.store.commit('cart/setOrder', order)
    }
    return order
  }

  async removeBooking(
    bookingId: string,
    order?: Order | null,
    setActiveOrder = true
  ): Promise<Order | null> {
    if (!order) {
      order = this.activeOrder
    }
    const apiService = this.ctx.$jsonApiService
    // send request
    const response = await new QueryBuilder(
      `${Order.apiPath}/order/bookings/${bookingId}`,
      apiService.getClient(Order),
      apiService
    )
      .include(defaultOrderInclude)
      .query(this.getActiveOrderQueryParams(order))
      .request('', 'delete')

    if (response.data) {
      order = Order.resourceFromResponse(response.data, apiService).data
    }

    // If response code is 204, it was the last booking deleted
    if (setActiveOrder) {
      if (response.status === 204) {
        // remove order
        await this.ctx.store.dispatch('cart/clearCart')
        return null
      }
      this.ctx.store.commit('cart/setOrder', order)
    }
    return order
  }

  async applyVoucher(
    voucherCode: string | null,
    order?: Order | null,
    setActiveOrder = true
  ): Promise<Order | null> {
    if (!order) {
      order = this.activeOrder
    }
    const apiService = this.ctx.$jsonApiService
    const builder = new QueryBuilder(
      `${Order.apiPath}/order/voucher`,
      apiService.getClient(Order),
      apiService
    )
      .include(defaultOrderInclude)
      .query(this.getActiveOrderQueryParams(order))

    let response: any
    // remove voucher
    if (voucherCode === null || voucherCode === '') {
      response = await builder.request('', 'delete')
    }
    // add voucher
    else {
      response = await builder.request('', 'post', {
        data: {
          voucher_code: voucherCode,
        },
      })
    }
    order = Order.resourceFromResponse(response.data, apiService).data
    if (setActiveOrder) {
      this.ctx.store.commit('cart/setOrder', order)
    }
    return order
  }

  async loadRecommendations() {
    const apiService = this.ctx.$jsonApiService
    const builder = new QueryBuilder(
      `${Order.apiPath}/order/recommendations`,
      apiService.getClient(Order),
      apiService
    ).query(this.getActiveOrderQueryParams(null, true))

    const response = await builder.request()
    return response.data
  }

  async removeVoucher(order?: Order | null): Promise<Order | null> {
    return await this.applyVoucher(null, order)
  }
}

export const shopServicePluginFactory = (): Plugin => {
  return async function (ctx: Context, inject) {
    const previewToken = ctx.route.query.preview_token
    const service = new ShopService(
      ctx,
      ctx.query,
      ctx.store,
      ctx.$jsonApiService,
      ctx.$embedService,
      ctx.$cookies,
      ctx.$config.useSecureCookies,
      previewToken ? String(previewToken) : null
    )

    const observableService = Vue.observable(service)

    inject('shopService', observableService)
  }
}
